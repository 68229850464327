<template>
   <div class="row">
      <div class="col-12">
         <div class="container">
            <div class="card">
               <div class="bg-banner">
                  <h1>{{ $t("navBar.serverSettings") }}</h1>
               </div>
               <div class="card-body p-4">
                  <div class="row m-0">
                     <div class="col-12 px-1 mb-1">
                        <h6 class="text-center mb-1"><i class="far fa-gamepad-alt color-theme font-13 mr-1"></i> {{ $t("donateConfig.configPage.server") }}</h6>
                        <p class="text-center mb-0">{{ $t("donateConfig.configPage.serverBenefits") }}</p>
                        <hr>
                     </div>
                     <div class="col-xl-6 mb-3">
                        <label class="mb-1"><i class="far fa-ellipsis-h font-13 color-theme mr-1"></i> {{ $t("donateConfig.configPage.description") }}</label>
                        <textarea type="text" class="form-control" v-model="info.description" maxlength="200"></textarea>
                     </div>
                     <div class="col-xl-6 mb-3">
                        <label class="mb-1"><i class="far fa-tags font-13 color-theme mr-1"></i> {{ $t("donateConfig.configPage.tags") }}</label>
                        <textarea type="text" class="form-control" v-model="info.tags" maxlength="200"></textarea>
                     </div>
                     <div class="mb-3" :class="serverS.donate ? 'col-xl-6' : 'col-12'">
                        <div class="row m-0">
                           <div class="col-12">
                              <label class="mb-1"><i class="far fa-link font-13 color-theme mr-1"></i> {{ $t("donateConfig.configPage.url") }}</label>
                           </div>
                           <div class="col">
                              <input type="text" class="form-control" v-model="info.invite" maxlength="200" />
                           </div>
                           <div class="w-max-content">
                              <button type="button" class="btn btn-primary" @click="gerarConvite">{{ $t("donateConfig.configPage.generate") }}</button>
                           </div>
                        </div>
                     </div>
                     <div class="col-xl-6 mb-3" v-if="serverS.donate">
                        <label class="mb-1"><i class="far fa-image font-13 color-theme mr-1"></i> {{ $t("donateConfig.configPage.urlBanner") }}</label>
                        <input type="text" class="form-control" v-model="info.banner" maxlength="200" />
                     </div>
                     <div class="col-12 px-1 text-right">
                        <button type="button" class="btn btn-primary" @click="saveInfo">{{ $t("donateConfig.configPage.save") }}</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'ServerSettings',
   data: function () {
		return {
         info: {}
      }
   },
   computed: {
		...mapState({
         dadosUsuario: state => state.dadosUsuario,
			urlRest: state => state.urlRest,
			serverS: state => state.serverS
		})
	},
   methods: {
      getInfoServer : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicos/getinfoserver',
            params: {
               idServer: this.serverS.id_s
            }
         }).then(response => {
            this.info = response.data;

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      gerarConvite : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/createserverinvite',
				params: {
               idServer: this.serverS.id_s
            }
         }).then(response => {
            this.info.invite = response.data;

            this.$toast.fire({
               icon: 'success',
               title: this.$t("serverSettings.swal.inviteCreate")
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      saveInfo : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/saveinfoserver',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.info

         }).then(() => {
            this.$toast.fire({
               icon: 'success',
               title: this.$t("serverSettings.swal.infoSaved")
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      }
   },
   mounted() {
      this.getInfoServer()
   }
}

</script>

<style scoped>

.grid-principal {
   border: 1px solid #FF7321;
   border-radius: 5px;
}

.suport-us {
   width: 250px
}

.tp-hero__title-lg {
	font-weight: 600;
	font-size: 40px;
	color: #fff;
	line-height: 1.2;
}

.btnPag {
   display: inline-block;
   padding: 15px 30px;
   font-size: 18px;
   font-weight: 700;
   border: 1px dashed #FF7321;
   cursor: pointer;
   position: relative;
   background-color: transparent;
   overflow: hidden;
   z-index: 1;
   border-radius: 5px;
   width: max-content;
   min-width: max-content;
}

.btnPag::before {
   content: "";
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   background-color: #FF7321;
   transform: translateX(-100%);
   transition: all .3s;
   z-index: -1;
}

.btnPag:hover::before {
   transform: translateX(0);
}

.title-grid {
   font-weight: 700;
   font-size: 17px;
   margin-bottom: 15px;
   color: #FF7321;
}

.text-grid {
   padding-top: 15px;
   padding-bottom: 20px;
   font-weight: 500;
   font-size: 17px;
   border-bottom: solid 1px rgba(145, 145, 145, 0.438);
   font-style: italic;
}

.icon-has {
   color: rgb(0, 192, 0);
}

.icon-dhas {
   color: rgb(255, 8, 0);
}

.icon-qhas {
   color: rgb(100, 100, 100);
}

</style>